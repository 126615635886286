<template>
  <div class="kt-container">
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("marks.title") }}
          </template>
          <template v-slot:toolbar>
          </template>
          <template v-slot:body>
            <SubjectInfo :subject="subjectInfo"></SubjectInfo>
            <div class="view">
              <div style="overflow-x: auto" class="table-container">
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th class="text-left sticky-col first-column"></th>
                    <th class="text-left sticky-col second-column"><i class="fa fa-calendar"></i> </th>
                    <th colspan="3">
                      {{ formData.lesson.subjectDateString }}
                    </th>
                  </tr>
                  <tr>
                    <th class="text-left sticky-col first-column"></th>
                    <th class="text-left sticky-col second-column"><i class="fa fa-clock"></i></th>
                    <th colspan="3">
                      {{ formData.lesson.lessonTimeString }}
                    </th>
                  </tr>
                  <tr>
                    <th class="text-left sticky-col first-column"></th>
                    <th class="text-left sticky-col second-column">{{ $t("marks.lessonContent") }}</th>
                    <th colspan="3">
                      <div class="th-data">{{ formData.lesson.lessonContent }}</div>
                      <button class="btn btn-primary btn-sm btn-icon" @click="editLessonContent(formData.lesson)" 
                              :title="$t('marks.lessonContent')" 
                              v-if="formData.lesson.eduPlanItemId == 0 && formData.lesson.isEditable"
                      >
                        <i class="fa fa-pen"></i>
                      </button>
                    </th>
                  </tr>
                  <tr>
                    <th class="text-left sticky-col first-column"></th>
                    <th class="text-left sticky-col second-column">{{ $t("marks.homeWork") }}</th>
                    <th colspan="3">
                      <div class="th-data">{{ formData.lesson.homework }}</div>
                      <button class="btn btn-primary btn-sm btn-icon" @click="editHw(formData.lesson)" 
                              :title="$t('marks.homeWork')" 
                              v-if="formData.lesson.eduPlanItemId == 0 && formData.lesson.isEditable">
                        <i class="fa fa-pen"></i>
                      </button>
                    </th>
                  </tr>
                  <tr>
                    <th class="text-left sticky-col first-column"></th>
                    <th class="text-left sticky-col second-column">План</th>
                    <th>
                      <div class="th-data">{{ formData.lesson.eduPlanTitle }}</div>
                      <button class="btn btn-primary btn-sm btn-icon" @click="selectPlanItem(formData.lesson)" v-if="formData.lesson.isEditable">
                        <i class="fa fa-book"></i>
                      </button>  
                      <button class="btn btn-danger btn-sm btn-icon" @click="unlinkPlanItem(formData.lesson)" v-if="formData.lesson.eduPlanItemId !== 0 && formData.lesson.isEditable" style="margin-left: 10px">
                        <i class="fa fa-window-close"></i>
                      </button> 
                    </th>
                  </tr>
                  <tr>
                    <th class="text-left sticky-col first-column"></th>
                    <th class="text-left sticky-col second-column">Есть вторая оценка</th>
                    <th>
                      <b-form-group>
                        <template v-slot:label>

                        </template>
                        <b-form-checkbox
                            v-model="hasSecondMark"
                            @change="changeHasSecondMark"
                        ></b-form-checkbox>
                      </b-form-group>
                    </th>
                  </tr>
                  <tr>
                    <th class="text-left sticky-col first-column">#</th>
                    <th class="text-left sticky-col second-column">
                      {{ $t("marks.list.fio") }}
                    </th>
                    <th width="50px">
                      {{ $t("marks.list.mark") }}
                    </th>
                    <th width="50px" v-if="hasSecondMark === true">
                      {{ $t("marks.list.mark2") }}
                    </th>
                    <th width="50px">
                      {{ $t("marks.list.note") }}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="(student, index) in formData.students"
                      :key="student.studentId"
                  >
                    <td class="sticky-col first-column">{{ index + 1 }}</td>
                    <td class="sticky-col second-column">
                      {{ student.studentName }}

                      <div>
                        <span v-for="(markItem, markItemIndex) in student.markList" :key="markItemIndex" class="mark-item">
                          {{ displayMark(markItem) }}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div v-if="student.exemptions != null && student.exemptions.includes(formData.lesson.subjectDateString)" style="color: red; font-weight: bold">Осв</div>
                      <b-form-group v-if="!(student.exemptions != null && student.exemptions.includes(formData.lesson.subjectDateString))">
                        <b-form-select
                            :disabled="!formData.lesson.isEditable"
                            v-model="
                              marks[student.studentId][
                                formData.lesson.id
                              ]
                            "
                            :options="marksList"
                            v-on:change="
                              changeMark(
                                student.studentId,
                                formData.lesson.subjectId,
                                formData.lesson.id,
                                formData.lesson.subjectNumber,
                                $event
                              )
                            "
                            :style="{ width: '100px' }"
                        ></b-form-select>
                      </b-form-group>
                    </td>
                    <td v-if="hasSecondMark === true">
                      <b-form-group v-if="!(student.exemptions != null && student.exemptions.includes(formData.lesson.subjectDateString))">
                        <b-form-select
                            :disabled="!formData.lesson.isEditable"
                            v-model="
                              marks2[student.studentId][
                                formData.lesson.id
                              ]
                            "
                            :options="marksList"
                            v-on:change="
                              changeMark2(
                                student.studentId,
                                formData.lesson.subjectId,
                                formData.lesson.id,
                                formData.lesson.subjectNumber,
                                $event
                              )
                            "
                            :style="{ width: '100px' }"
                        ></b-form-select>
                      </b-form-group>
                    </td>
                    <td>
                      <textarea v-model="notes[student.studentId][formData.lesson.id]"
                                v-if="!(student.exemptions != null && student.exemptions.includes(formData.lesson.subjectDateString))"
                                v-on:change="
                              changeNote(
                                student.studentId,
                                formData.lesson.subjectId,
                                formData.lesson.id,
                                formData.lesson.subjectNumber,
                                $event
                              )
                            "
                      style="border: 1px solid black"
                                
                      >
                        
                      </textarea>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>


            <div>
              <b-modal id="video-modal" ref="video-modal" size="xl">
                <template v-slot:modal-title>
                  {{ $t("videoLesson.title") }}
                </template>
                <div id="meet"></div>
                <template v-slot:modal-footer>
                  <b-button type="reset" @click="onReset" variant="danger">{{
                      $t("common.cancel")
                    }}
                  </b-button>
                </template>
              </b-modal>
              <b-modal id="hw-modal" ref="hw-modal">
                <template v-slot:modal-title>
                  {{ $t("marks.form.hw") }}
                </template>
                <div class="change-hw-div">
                  <label>{{ $t("marks.form.day") }}</label>
                  <span>{{ hw.subjectDateString }}</span>
                </div>
                <div>
                  <label>{{ $t("marks.form.number") }}</label>
                  <span>{{ hw.subjectNumber }}</span>
                </div>
                <b-form>
                  <b-form-group id="input-group-4" label-for="input-4">
                    <template v-slot:label>
                      {{ $t("marks.form.hw") }}
                    </template>
                    <b-form-textarea
                        id="input-4"
                        v-model="hw.homework"
                        required
                    ></b-form-textarea>
                  </b-form-group>
                </b-form>
                <template v-slot:modal-footer>
                  <b-button type="submit" @click="changeHw" variant="primary">{{
                      $t("common.save")
                    }}
                  </b-button>
                  <b-button type="reset" @click="onResetHw" variant="danger">{{
                      $t("common.cancel")
                    }}
                  </b-button>
                </template>
              </b-modal>
              <b-modal id="lesson-content-modal" ref="lesson-content-modal">
                <template v-slot:modal-title>
                  {{ $t("marks.lessonContentForm.lessonContent") }}
                </template>
                <div class="change-hw-div">
                  <label>{{ $t("marks.lessonContentForm.day") }}</label>
                  <span>{{ hw.subjectDateString }}</span>
                </div>
                <div>
                  <label>{{ $t("marks.lessonContentForm.number") }}</label>
                  <span>{{ hw.subjectNumber }}</span>
                </div>
                <b-form>
                  <b-form-group id="input-group-4" label-for="input-4">
                    <template v-slot:label>
                      {{ $t("marks.lessonContentForm.lessonContent") }}
                    </template>
                    <b-form-textarea
                        id="input-4"
                        v-model="lessonContent.lessonContent"
                        required
                    ></b-form-textarea>
                  </b-form-group>
                </b-form>
                <template v-slot:modal-footer>
                  <b-button type="submit" @click="changeLessonContent" variant="primary">{{
                      $t("common.save")
                    }}
                  </b-button>
                  <b-button type="reset" @click="onResetLessonContent" variant="danger">{{
                      $t("common.cancel")
                    }}
                  </b-button>
                </template>
              </b-modal>
              <b-modal id="save-online-lesson-data-modal" ref="save-online-lesson-data-modal">
                <template v-slot:modal-title>
                  Отправить ссылку ученикам
                </template>
                <b-form>
                  <b-form-group id="input-group-4" label-for="input-4">
                    <template v-slot:label>
                      Ссылка
                    </template>
                    <b-form-textarea
                        id="input-4"
                        v-model="onlineLessonLink.lessonLink"
                        required
                    ></b-form-textarea>
                  </b-form-group>
                </b-form>
                <template v-slot:modal-footer>
                  <b-button type="submit" @click="saveOnlineLessonData" variant="primary">{{
                      $t("common.save")
                    }}
                  </b-button>
                  <b-button type="reset" @click="onResetSaveOnlineLessonData" variant="danger">{{
                      $t("common.cancel")
                    }}
                  </b-button>
                </template>
              </b-modal>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
    <PlanSelectModal :group-subject-id="groupSubjectId" 
                     v-on:planItemSelected="planItemSelected"
                     ref="planSelectModal"></PlanSelectModal>
  </div>
</template>

<style>
/*  table, th, td {
    border: 1px solid black;
  }*/
.th-button {
  padding-bottom: 10px !important;
}

.exit-hw {
  padding: 2px !important;
  color: #fff;
  background-color: #fc0758;
  border-color: #f40252;
}

.change-hw-div > label {
  display: inline-block;
  margin-right: 10px;
}

.th-data {
  min-height: 50px;
  min-width: 50px;
  padding-top: 10px;

  max-height: 100px;
  overflow-y: auto;
}

.th-button {
  background-color: #f7f8fa;
}

.hw-title {
  margin-top: 20px;
  font-style: italic;
  font-weight: bold;
  padding: 5px;
  background-color: #384;
  color: white;
  white-space: normal !important;
}

.lesson-content-title{
  font-style: italic;
  font-weight: bold;
  padding: 5px;
  background-color: #336388;
  color: white;
  white-space: normal !important;
}

.view {
  margin: auto;
  width: 100%;
}

.table-container {
  position: relative;
  overflow: auto;
  /*white-space: nowrap;*/
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
}

.first-column {
  width: 10px;
  min-width: 10px;
  max-width: 10px;
  left: 0;
}

.second-column {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  left: 20px;
}

.mark-item{
  display: inline-block;
  padding-right: 10px;
  font-weight: bold;
  color: blue;
}
</style>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";
import SubjectInfo from "./Marks/partials/SubjectInfo.vue";
import PlanSelectModal from "./Marks/partials/PlanSelectModal.vue";
//const JitsiMeetExternalAPI = require("lib-jitsi-meet-dist/dist/external_api.min");
//const JitsiMeetExternalAPI = require("@/common/jitsi/external_api");
//import JitsiMeetExternalAPI from 'JitsiMeetExternalAPI';

//import { VIDEO_STEAM_SERVER_URL } from "@/common/config";

export default {
  name: "marks",
  components: {
    PlanSelectModal,
    SubjectInfo,
    KTPortlet
  },
  data() {
    return {
      groupSubjectId: "",
      lessonId: this.$route.params.lessonId,
      subjectName: null,
      hw: {},
      lessonContent: {},
      onlineLessonLink: {},
      month: 9,
      marksList: DictionariesService.getMarksForLesson(),
      marks: {},
      marks2: {},
      notes: {},
      formData: {
        lesson: {}
      },
      subjectInfo:{},
      hasSecondMark: false
    };
  },
  mounted() {
    this.month = new Date().getMonth() + 1;

    this.reloadData();
  },
  methods: {
    changeMark(studentId, subjectId, lessonId, subjectNumber, event) {
      let markVal = event; /*.target.value*/

      let mark = {
        subjectId: subjectId,
        studentId: studentId,
        lessonId: lessonId,
        mark: markVal,
      };

      ApiService.postSecured("marks/save", mark)
          .then(function () {

          })
          .catch(({response}) => {
            console.log(response);

            alert("Ошибка, перезайдите или обратитесь к администратору");
          });
    },   
    changeMark2(studentId, subjectId, lessonId, subjectNumber, event) {
      let markVal = event; /*.target.value*/

      let mark = {
        subjectId: subjectId,
        studentId: studentId,
        lessonId: lessonId,
        mark2: markVal,
      };

      ApiService.postSecured("marks/saveMark2", mark)
          .then(function () {

          })
          .catch(({response}) => {
            console.log(response);

            alert("Ошибка, перезайдите или обратитесь к администратору");
          });
    }, 
    changeNote(studentId, subjectId, lessonId/*, subjectNumber, event*/) {
      //let markVal = event; /*.target.value*/

      let mark = {
        subjectId: subjectId,
        studentId: studentId,
        lessonId: lessonId,
        teacherNote: this.notes[studentId][lessonId],
      };

      ApiService.postSecured("marks/saveTeacherNote", mark)
          .then(function () {

          })
          .catch(({response}) => {
            console.log(response);

            alert("Ошибка, перезайдите или обратитесь к администратору");
          });
    },
    reloadData() {
      let $this = this;

      ApiService.querySecured("marks/getForLesson", {
        params: {lessonId: this.lessonId}
      })
          .then(({data}) => {
            $this.marks = {};

            for (let i in data.marks.students) {
              let student = data.marks.students[i];

              $this.marks[student.studentId] = {};
              $this.marks2[student.studentId] = {};
              $this.notes[student.studentId] = {};

              for (let ii in data.marks.lessons) {
                let lesson = data.marks.lessons[ii];

                $this.marks[student.studentId][lesson.id] = {};
                $this.marks2[student.studentId][lesson.id] = {};
                $this.notes[student.studentId][lesson.id] = {};
              }
            }

            let marks = data.marks.marks;
            for (let i in marks) {
              let item = marks[i];

              if (typeof $this.marks[item.studentId] != 'undefined') {

                $this.marks[item.studentId][item.lessonId] = item.mark;
                $this.marks2[item.studentId][item.lessonId] = item.mark2;
                $this.notes[item.studentId][item.lessonId] = item.note;
              }
            }
            
            $this.formData = data.marks;            
            $this.subjectInfo = data.marks.subjectInfo;
            $this.hasSecondMark = data.marks.lesson.hasSecondMark;
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    addOnlineLessonData(lesson) {
      this.onlineLessonLink = {
        lessonId: lesson.id,
        lessonLink: ''
      };
      this.$refs["save-online-lesson-data-modal"].show();
    },
    saveOnlineLessonData() {
      let lessonData = {
        lessonId: this.onlineLessonLink.lessonId,
        link: this.onlineLessonLink.lessonLink
      };
      ApiService.postSecured("lesson/saveOnlineLessonData", lessonData)
          .then(function (/*data*/) {
            this.$refs["save-online-lesson-data-modal"].hide();
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    onResetSaveOnlineLessonData(evt) {
      evt.preventDefault();
      this.onlineLessonLink = {};
      this.$refs["save-online-lesson-data-modal"].hide();
    },
    startVideoLesson(lesson) {
      this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
        console.log('Modal is about to be shown', bvEvent, modalId);
        if (modalId == 'video-modal') {

          let lessonData = {
            subjectId: lesson.subjectId,
            date: lesson.subjectDateString,
            subjectNumber: lesson.subjectNumber
          };
          ApiService.postSecured("lesson/createRoom", lessonData)
              .then(function (/*data*/) {
                document.querySelector('#meet').innerHTML = '';

                /*const domain = VIDEO_STEAM_SERVER_URL;
                const options = {
                  roomName: data.data.streamId.roomName,
                  width: 700,
                  height: 700,
                  parentNode: document.querySelector('#meet')
                };*/
                /*let api = new JitsiMeetExternalAPI(domain, options);
                api.executeCommand('password', '123');
                api.executeCommand('displayName', 'Test');*/
              })
              .catch(({response}) => {
                console.log(response);
              });
        }
      });

      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        console.log('Modal is about to be shown', bvEvent, modalId);
        if (modalId == 'video-modal') {
          document.querySelector('#meet').innerHTML = '';
        }
      });

      this.$refs["video-modal"].show();
    },
    onReset(evt) {
      evt.preventDefault();
      this.$refs["video-modal"].hide();
    },
    editHw(lesson) {
      this.hw = lesson;
      this.$refs["hw-modal"].show();
    },
    changeHw(evt) {
      evt.preventDefault();
      let $this = this;
      let lessonData = {
        id: $this.hw.id,
        subjectId: $this.hw.subjectId,
        subjectNumber: $this.hw.subjectNumber,
        homeTask: $this.hw.homework
      };
      ApiService.postSecured("lesson/saveHomeTask", lessonData)
          .then(function () {
            $this.$refs["hw-modal"].hide();
            $this.reloadData();
            $this.hw = {};
          })
          .catch(({response}) => {
            console.log(response);
            $this.reloadData();

            alert("Ошибка, перезайдите или обратитесь к администратору");
          });
    },
    onResetHw(evt) {
      evt.preventDefault();
      this.hw = {};
      this.$refs["hw-modal"].hide();
    },
    editLessonContent(lesson) {
      this.lessonContent = lesson;
      this.$refs["lesson-content-modal"].show();
    },
    changeLessonContent(evt) {
      evt.preventDefault();
      let $this = this;
      let lessonData = {
        id: $this.lessonContent.id,
        subjectId: $this.lessonContent.subjectId,
        subjectNumber: $this.lessonContent.subjectNumber,
        content: $this.lessonContent.lessonContent
      };
      ApiService.postSecured("lesson/saveLessonContent", lessonData)
          .then(function () {
            $this.$refs["lesson-content-modal"].hide();
            $this.reloadData();
            $this.lessonContent = {};
          })
          .catch(({response}) => {
            console.log(response);
            $this.reloadData();
            alert("Ошибка, перезайдите или обратитесь к администратору");
          });
    },
    onResetLessonContent(evt) {
      evt.preventDefault();
      this.lessonContent = {};
      this.$refs["lesson-content-modal"].hide();
    },
    selectPlanItem(){
      this.$refs['planSelectModal'].reloadData(this.subjectInfo.subjectId);
      this.$refs['planSelectModal'].openSelectModal();
    },
    planItemSelected(id) {
      let model = {
        lessonId: this.lessonId,
        planId: id,
      };
      let $this = this; 
      ApiService.postSecured("eduPlans/linkPlanItemToLesson", model)
          .then(function () {            
            $this.reloadData();
          })
          .catch(({response}) => {
            console.log(response);
            $this.reloadData();
            alert("Ошибка, перезайдите или обратитесь к администратору");
          });
    },
    unlinkPlanItem(plan) {      
      if(confirm("Отвязать план?")){
        let model = {
          lessonId: this.lessonId,
          planId: plan.id,
        };
        let $this = this;
        ApiService.postSecured("eduPlans/unLinkPlanItemToLesson", model)
            .then(function () {
              $this.reloadData();
            })
            .catch(({response}) => {
              console.log(response);
              $this.reloadData();
            });
      }     
    },
    changeHasSecondMark() {
      let lessonData = {
        lessonId: this.lessonId,
        hasSecondMark: this.hasSecondMark
      };
      ApiService.postSecured("lesson/SaveLessonHasSecondMark", lessonData)
          .then(function () {
            
          })
          .catch(({response}) => {
            console.log(response);
            alert("Ошибка, перезайдите или обратитесь к администратору");
          });
    },
    displayMark(lesson){
      let ret = DictionariesService.displayMark(lesson.mark);
      if(lesson.mark2 != null){
        ret += "/" + DictionariesService.displayMark(lesson.mark2)
      }
      return ret;
    },
  }
};
</script>
